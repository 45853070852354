import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl = `${environment.iauthUrl}/api/v1/clients/web/admin`;
  env = environment;

  constructor( private http: HttpClient) {}

  public isAuthenticated(): any {
    return sessionStorage.getItem('_token');
  }

  public verifyPassword(password?: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + sessionStorage.getItem('_token'),
        Accept: 'application/json',
        'Content-Type': 'application/json'
      })
    };
    const body = { password };
    if (!this.env.production) {
      httpOptions.headers = httpOptions.headers.append(
        'X-Bp-User', sessionStorage.getItem('_xBpUser') || ''
      );
    }
    return this.http.post(this.baseUrl + '/verifypassword', body, httpOptions);
  }
}
